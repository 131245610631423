<template>
  <v-col cols="12" md="3" offset-md="5" align="center" class="mt-12">
    <h1>{{ $vuetify.lang.t("$vuetify.view.emailRegistration.title") }}</h1>
    <p class="font-weight-regular mt-5">
      {{ $vuetify.lang.t("$vuetify.view.emailRegistration.desk") }}
    </p>
    <v-form @submit.prevent="validate" ref="form" v-model="valid">
      <v-text-field
        v-model="email"
        :rules="emailRules"
        label="E-mail"
        required
      ></v-text-field>
    </v-form>
    <v-btn
      class="ma-2 mt-7"
      color="primary"
      outlined
      @click="validate"
      :disabled="!valid"
    >
      {{ $vuetify.lang.t("$vuetify.view.emailRegistration.accept") }}
      <template v-slot:loader>
        <span>{{
          $vuetify.lang.t("$vuetify.view.emailRegistration.loading")
        }}</span>
      </template>
    </v-btn>
  </v-col>
</template>

<script>
export default {
  name: "emailRegistrationComponent",

  data() {
    return {
      url: "",
      valid: false,
      email: "",
      emailRules: [
        (v) =>
          !!v ||
          this.$vuetify.lang.t("$vuetify.view.emailRegistration.emailRules1"),
        (v) =>
          /.+@.+/.test(v) ||
          this.$vuetify.lang.t("$vuetify.view.emailRegistration.emailRules2"),
      ],
    };
  },
  methods: {
    validate() {
      if (this.valid) {
        this.$store.dispatch("user/setEmail", this.email);
        this.sendEmailNumber();
      }
    },
    async sendEmailNumber() {
      this.loader = "loading";
      let payload = {
        email: this.email,
      };

      await this.$store.dispatch(
        "user/sendPsychEmailCode",
        JSON.stringify(payload)
      );
    },
  },
  created() {
    this.url = this.$route.meta.url;
  },
};
</script>
